import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharingDataService {

  public circuitData = new BehaviorSubject<any>(false);
  public changeClick=new BehaviorSubject<any>(false);
  public layoutcheck=new BehaviorSubject<any>(false);
  public aliasSelected=new BehaviorSubject<any>(false)
  public profileDetailsClicked=new BehaviorSubject<any>(false)
  public profileShouldOpen=new BehaviorSubject<any>(false)
  public penaltyAdded=new BehaviorSubject<any>(false);
  public scoreMismatch=new BehaviorSubject<any>(false)
  public isProfileSwitched$=new BehaviorSubject<boolean>(false)
  public isProfileSelected$=new BehaviorSubject<boolean>(false)
  public isProfileChanged$=new BehaviorSubject<boolean>(false)
  public isCarAdded$=new BehaviorSubject<boolean>(false)
  public toasterToBeDisplayed$=new BehaviorSubject<boolean>(false)
  public carAddedSuccessfully$= new BehaviorSubject<boolean>(false)
  public mobileMenuVisibility = new BehaviorSubject<any>(false);

  setMobileMenuStatus(status: boolean) {
    this.mobileMenuVisibility.next(status);
  }

  getMobileMenuStatus() {
    return this.mobileMenuVisibility.asObservable();
  }


  setCircuitName(data:any){
    this.circuitData.next(data)
   }
 
   getCircuitName(){
     return this.circuitData.asObservable()
   }

   setChangeClicked(isBoolean:boolean){
    this.changeClick.next(isBoolean)
   }
 
   getChangeClicked(){
     return this.changeClick.asObservable()
   }

   setLayoutCheck(isBoolean:boolean){
    this.layoutcheck.next(isBoolean)
   }
 
   getLayoutCheck(){
     return this.layoutcheck.asObservable()
   }

   setAliasList(isBoolean:boolean){
    this.aliasSelected.next(isBoolean)
   }

   getAliasList(){
    return this.aliasSelected.asObservable() 
   }

   setProfileDetailsClicked(isBoolean:boolean){
    this.profileDetailsClicked.next(isBoolean)
   }

   getProfileDetailsClicked(){
    return this.profileDetailsClicked.asObservable() 
   }

   setProfileOpen(isBoolean:boolean){
    this.profileShouldOpen.next(isBoolean)
   }
   getProfileOpen(){
    return this.profileShouldOpen.asObservable() 
   }
   setPenaltyAdded(isBoolean:boolean){
    this.penaltyAdded.next(isBoolean)
   }
   getPenaltyAdded(){
    return this.penaltyAdded.asObservable()
   }

   setScoreMatchCheck(isBoolean:boolean){
    this.scoreMismatch.next(isBoolean)
   }

   getScoreMatchCheck(){
    return this.scoreMismatch.asObservable()
   }

   setProfileSwitched(isBoolean:boolean){
        this.isProfileSwitched$.next(isBoolean)
   }
   getProfileSwitched(){
    return this.isProfileSwitched$.asObservable()
   }

   setProfileSelected(isBoolean:boolean){
    this.isProfileSelected$.next(isBoolean)
   }
    getProfileSelected(){
    return this.isProfileSelected$.asObservable()
   }

   setProfileChanged(isBoolean:boolean){
    this.isProfileChanged$.next(isBoolean)
   }
   getProfileChanged(){
    return this.isProfileChanged$.asObservable()
   }

   setCarAdded(isBoolean:boolean){
    this.isCarAdded$.next(isBoolean)
   }
   getCardAdded(){
    return this.isCarAdded$.asObservable()
   }

   setToasterToBeDisplayed(isBoolean:boolean){
    this.toasterToBeDisplayed$.next(isBoolean)
   }

   getToasterToBeDisplayed(){
    return this.toasterToBeDisplayed$.asObservable()
   }

   setCarAddedSuccessfully(isBoolean:boolean){
    this.carAddedSuccessfully$.next(isBoolean)
   }
   getCarAddedSuccessfully(){
    return this.carAddedSuccessfully$.asObservable()
   }
  
}

